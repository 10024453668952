/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../src/assets/theme/choruby-custom/theme.css";

html {
  font-size: 14px;
}
.active-link {
  background-color: #f5f5f5;
  color: #003767;
  border-radius: 5px;
}

.ui-orderlist-item {
  touch-action: none;
}

.ui-orderlist-draghandle {
  touch-action: auto;
}